import React, { ReactElement } from "react";

import styles from "./style.module.scss";

const points = [
    {
        id: 1,
        text: "Organizations can spend up to 3% of annual revenues on print.",
    },
    {
        id: 2,
        text: "Managing your printers and supplies can reduce your overall printing costs up to 20% to 30%.",
    },
    {
        id: 3,
        text: "90% of companies don’t know how much they spend on print.",
    },
    {
        id: 4,
        text: "By 2030, 500 billion devices and objects will be connected to the Internet.",
    },
    { id: 5, text: "1 in 5 data breaches involve paper records." },
    {
        id: 6,
        text: "Trash is considered public property. Identity theft thieves think like that.",
    },
];
export default function DoYouKnow(): ReactElement {
    return (
        <div className={styles["DoYouKnowWrapper"]}>
            <div className={` font-bold mb-16 xxl:mb-24 ${styles["title"]}`}>
                Did You Know?
            </div>
            <div className={`flex flex-row flex-wrap ${styles["points"]}`}>
                {points.map((point) => (
                    <div key={point.id} className={`${styles["point"]}`}>
                        <div className={`font-bold   ${styles["num"]}`}>
                            {point.id}
                        </div>
                        <div className={styles["pointText"]}>{point.text}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}
